body.dark-mode {
    background-color: $dark-bg;
    color: $dark-text;

    input {
        background-color: $dark-card-bg;
        color: $dark-text;
        border: 2px solid $dark-primary-color;
    }

    .app-container {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        background: $dark-background-gradient; // Default light background

        // // When dark mode is enabled, apply the dark background gradient
        // &.dark-mode {
        //     background: $dark-background-gradient !important; // Force the dark background to override the light one
        // }

        &.centered {
            justify-content: center;
            align-items: center;
        }
    }

    /* Book Card in Dark Mode */
    .book-card {
        background-color: rgba($dark-card-bg, 0.9);
        color: $dark-text;
        border: 1px solid $dark-border;

        &:hover {
            transform: translateY(-5px);
            border-color: $dark-primary-color;
        }

        .book-title {
            color: $dark-primary-color;
        }

        .book-score {
            color: $dark-secondary-color;
        }

        .book-description,
        .book-subject {
            color: $dark-text;
        }

        .link-button {
            background-color: $dark-primary-color;
            color: $dark-bg;

            &:hover {
                background-color: darken($dark-primary-color, 10%);
            }
        }
    }

    /* Dark Mode Toggle */
    .dark-mode-toggle {
        background-color: $dark-primary-color;
        color: $dark-bg;

        &:hover {
            background-color: lighten($dark-primary-color, 10%);
        }
    }

    /* Loading Overlay in Dark Mode */
    .loading-overlay {
        background-color: rgba(18, 18, 18, 0.8);

        p {
            color: $dark-text;
        }
    }

    /* Spinner in Dark Mode */
    .spinner {
        border-color: rgba($dark-primary-color, 0.3);
        border-top-color: $dark-primary-color;
    }

    /* Header in Dark Mode */
    header h1 {
        color: $dark-primary-color;
    }
npm
    header p {
        color: $dark-secondary-color;
    }

    /* Filter Label in Dark Mode */
    .filter label {
        color: $dark-secondary-color;
    }

    /* Error and No Results in Dark Mode */
    .error,
    .no-results {
        color: $dark-error-color;
    }
}

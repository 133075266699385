body {
    font-family: 'Raleway', sans-serif;
    background-color: $background-color;
    color: $text-color;
    margin: 0;
    padding: 0;
    line-height: 1.6;
}

button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

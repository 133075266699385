$primary-color: #3498db;
$secondary-color: #2c3e50;
$background-color: #ecf0f1;
$text-color: #333;
$error-color: #e74c3c;
$light-background-gradient: radial-gradient(circle, rgba(236, 240, 241, 0) 0%, rgba(236, 240, 241, 0.3) 100%);

$dark-bg: #121212;
$dark-card-bg: #1e1e1e;
$dark-text: #e0e0e0;
$dark-border: #373737;
$dark-primary-color: #90caf9;
$dark-secondary-color: #8e8e8e;
$dark-error-color: #cf6679;
$dark-background-gradient: radial-gradient(circle, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.3) 100%);


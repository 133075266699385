.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: $light-background-gradient; // Default light background

    // // When dark mode is enabled, apply the dark background gradient
    // &.dark-mode {
    //     background: $dark-background-gradient !important; // Force the dark background to override the light one
    // }

    &.centered {
        justify-content: center;
        align-items: center;
    }
}



main {
    flex: 1;
    width: 100%;
    // max-width: 1200px;
    margin: 0 auto;
    // padding: 20px;
}

/* Dark Mode Toggle Button */
.dark-mode-toggle {
    background-color: $primary-color;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    transition: background-color 0.3s ease;
    position: fixed;
    top: 20px;
    right: 20px;

    &:hover {
        background-color: darken($primary-color, 10%);
    }

    .switch-label {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
    }
}

/* App Container */
.app-container {
    // max-width: 1200px;
    margin: 0 auto;
    // padding: 20px;
}

header {
    position: relative;
    text-align: center;
    margin-bottom: 30px;
}

header .absolute {
    position: absolute;
}

.dark-mode-toggle {
    display: flex;
    align-items: center;
}

button.outline-none {
    border: none;
    background: none;
    padding: 8px;
    cursor: pointer;
}

h1 {
    color: #3498db;
    /* Adjust the color as per your theme */
}

p {
    color: #7f8c8d;
    /* Adjust the color as per your theme */
}

.search-container {
    width: 100%;
    transition: all 0.3s ease;

    &.centered {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
}

/* Search Container */
.search-container {
    position: relative;
    margin-bottom: 20px;

    input {
        width: 60%;
        margin-left: 20%;
        padding: 10px;
        font-size: 16px;
        border: 2px solid $primary-color;
        border-radius: 4px;
        outline: none;

        &:focus {
            box-shadow: 0 0 5px rgba($primary-color, 0.5);
        }
    }
}

/* Filter */
.filter {
    margin-bottom: 20px;

    label {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: $secondary-color;

        input {
            margin-right: 10px;
        }
    }
}

/* Error and No Results Messages */
.error,
.no-results {
    color: $error-color;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

/* Results Container */
.results-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.book-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 15px;
    /* Reduce padding */
    width: 250px;
    /* Set a narrower width */
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
    margin-right: 15px;
    /* Add space between cards in carousel */

    &:hover {
        transform: translateY(-5px);
    }

    .book-cover {
        width: auto;
        /* Set the cover to take the full width of the card */
        height: 300px;
        /* Maintain aspect ratio */
        object-fit: cover;
        margin-bottom: 1rem;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

        .book-cover img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: inherit;
        }

    .book-title {
        font-size: 1em;
        /* Reduce font size */
        margin-top: 0;
        margin-bottom: 8px;
        /* Adjust margin */
        color: $primary-color;
        text-align: center;
        text-wrap: balance;
        /* Center align title */
    }

    .book-score,
    .book-description,
    .book-subject {
        font-size: 0.85em;
        /* Reduce font size */
        margin-bottom: 8px;
        text-align: center;
        /* Center align text */
    }

    .book-description {
        height: 150px;
        text-wrap: balance;
    }

    .book-actions {
        margin-top: auto;
        display: flex;
        justify-content: center;
        gap: 8px;
        /* Adjust gap between action buttons */
    }

    .card-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 220px;
        height: 100%;
        flex-grow: 1;

        .spinner {
            width: 30px;
            height: 30px;
        }

        p {
            margin-top: 10px;
            font-size: 14px;
            color: $secondary-color;
        }
    }
}

.book-player {
    display: flex;
    align-items: center;
}

.audio-controls {
    display: flex;
    align-items: center;
    gap: 10px;
    /* Adds some spacing between the play button and track info */
}

.play-button {
    background: #4CAF50;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 20px;
    cursor: pointer;
    padding: 8px;
}

.track-stats {
    font-size: 16px;
    color: #fff;
}

/* Buttons */
.download-button,
.link-button {
    padding: 8px 12px;
    font-size: 14px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
    text-align: center;
}

.play-button {
    background-color: #27ae60;

    &:hover {
        background-color: darken(#27ae60, 10%);
    }
}

.download-button {
    background-color: #f39c12;

    &:hover {
        background-color: darken(#f39c12, 10%);
    }
}

.link-button {
    background-color: $primary-color;

    &:hover {
        background-color: darken($primary-color, 10%);
    }
}

/* Loading Overlay */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    p {
        margin-top: 20px;
        font-size: 18px;
        color: $secondary-color;
    }
}

/* Spinner */
.spinner {
    border: 4px solid rgba($primary-color, 0.3);
    border-radius: 50%;
    border-top: 4px solid $primary-color;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

/* Media Queries */
@media (max-width: 768px) {
    .results-container {
        grid-template-columns: 1fr;
    }

    .book-card {
        padding: 15px;
    }

    .play-button,
    .download-button,
    .link-button {
        padding: 6px 10px;
        font-size: 12px;
    }
}\\.dark-mode-toggle {
  background-color: #3498db;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: darken(#3498db, 10%);
  }

  .switch-label {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
  }
}

.outline-button {
  background-color: white;
  color: #3498db;
  padding: 10px 15px;
  border: 2px solid #3498db;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #3498db;
    color: white;
  }
}

.dark-mode-toggle {
    background-color: #3498db;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: darken(#3498db, 10%);
    }

    .switch-label {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
    }
}

.outline-button {
    background-color: white;
    color: #3498db;
    padding: 10px 15px;
    border: 2px solid #3498db;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background-color: #3498db;
        color: white;
    }
}

.toggle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.carousel-container {
    width: 100%;
    overflow-x: auto;
    /* Horizontal scrolling */
    white-space: nowrap;
    /* Prevent line breaks */
    padding: 20px;
}

.carousel {
    display: flex;
    flex-direction: row;
    gap: 15px;
    /* Space between cards */
}

.BookCard {
    display: inline-block;
    width: 200px;
    /* Adjust the width of each card */
    flex: 0 0 auto;
}

.audio-container {
padding: 20;
}

.audio-player-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}


.header-text {
    font-size: 48px;
}

.hidden {
    display: none;
}

.list-body {
    max-height: 180px;
}

.rm-audio-player-provider {
    opacity: 0.9;
}

// .spinner-results{
//     margin-top: 50%;
// }


.favorites-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
}

.book-card-container {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.book-card-container.fade-in {
    opacity: 1;
}

.book-card-container.fade-out {
    opacity: 0;
}